<template>
  <b-card
    class="blog-edit-wrapper"
    :title="formTitle"
  >
    <!-- form -->
    <validation-observer ref="eventoRules">
      <b-form class="mt-2">
        <b-row>
          <b-col md="12" class="mb-2">
            <div class="border rounded p-2">
              <h4 class="mb-1 text-center"><b>Foto Portada</b></h4>
              <div class="text-center">
                <b-img
                  v-if="imagen"
                  ref="refPreviewEl"
                  :src="imagen"
                  height="190"
                  width="500"
                  class="rounded mr-2 mb-1 mb-md-0"
                />
                <b-img
                  v-else
                  ref="refPreviewEl"
                  :src="evento.foto_portada"
                  height="190"
                  width="500"
                  class="rounded mr-2 mb-1 mb-md-0"
                />
                <b-form-file
                  ref="refInputEl"
                  v-model="eventoFile"
                  accept=".jpg, .png, .gif"
                  placeholder="Cambiar Fotov"
                  @input="inputImageRenderer"
                />
              </div>
            </div>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="Titulo evento"
              label-for="titulo"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Titulo evento"
                rules="required"
              >
                <b-form-input
                  id="titulo"
                  v-model="evento.titulo"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
                <small
                  v-for="error in errores.titulo"
                  :key="error"
                  class="text-danger"
                >{{ error }}</small>
                <!-- <small v-for="error in errores.nombre" :key="error" class="text-danger">{{ error }}</small> -->
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <CkEditorPersonalizado
              v-model="evento.descripcion"
            />
            <!-- <b-form-group
              label="Nombre evento"
              label-for="nombre-evento"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Nombre evento"
                rules="required"
              >
                <b-form-input
                  id="nombre-evento"
                  v-model="evento.nombre"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
                <small
                  v-for="error in errores.nombre"
                  :key="error"
                  class="text-danger"
                  >{{ error }}</small
                >
              </validation-provider>
            </b-form-group> -->
          </b-col>
          <!-- <b-col md="6" v-if="selected == '1'">
            <b-form-group
              label="Seleccione evento Principal"
              label-for="evento-dependiente"
              class="mb-2"
            >
              <v-select
                id="evento-dependiente"
                v-model="evento.eventoDependiente"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="eventoOption"
                label="nombre"
              />
            </b-form-group>
          </b-col> -->
        </b-row>
        <b-row>
          <b-col md="12">
            <h3 class="pt-2">
              Fecha y Hora del Evento
            </h3>
          </b-col>
          <b-col md="12">
            <b-form-checkbox
              id="asiento-fiscal"
              v-model="selected"
              value="1"
              unchecked-value="0"
              switch
            >
              <b>Todo el Dia</b>
            </b-form-checkbox>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <b-form-group
              label="Fecha"
              label-for="fecha"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Fecha"
                rules="required"
              >
                <b-form-datepicker
                  id="fecha"
                  v-model="evento.fecha"
                  placeholder="Ingrese la Fecha"
                  local="es"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
                <small
                  v-for="error in errores.fecha"
                  :key="error"
                  class="text-danger"
                >{{ error }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            v-if="selected==0"
            md="3"
          >
            <b-form-group
              label="Hora de Inicio"
              label-for="hora_inicio"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Hora Inicio"
                rules=""
              >
                <b-form-timepicker
                  id="hora_inicio"
                  v-model="evento.hora_inicio"
                  placeholder="ingrese la hora"
                  local="es"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
                <small
                  v-for="error in errores.hora_inicio"
                  :key="error"
                  class="text-danger"
                >{{ error }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            v-if="selected==0"
            md="3"
          >
            <b-form-group
              label="Hora de Final"
              label-for="hora_final"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Hora Final"
                rules="required"
              >
                <b-form-timepicker
                  id="hora_final"
                  v-model="evento.hora_final"
                  placeholder="ingrese la hora"
                  local="es"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
                <small
                  v-for="error in errores.hora_final"
                  :key="error"
                  class="text-danger"
                >{{ error }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="12">
            <h3>Detalle del Lugar</h3>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <b-form-group
              label="Nombre del Lugar"
              label-for="nombre_lugar"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Nombre del lugar"
                rules="required"
              >
                <b-form-input
                  id="nombre_lugar"
                  v-model="evento.nombre_lugar"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
                <small
                  v-for="error in errores.nombre_lugar"
                  :key="error"
                  class="text-danger"
                >{{ error }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Direccion"
              label-for="direccion"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Direccion"
                rules="required"
              >
                <b-form-input
                  id="direccion"
                  v-model="evento.direccion"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
                <small
                  v-for="error in errores.direccion"
                  :key="error"
                  class="text-danger"
                >{{ error }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Ciudad"
              label-for="ciudad"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Ciudad"
                rules="required"
              >
                <b-form-input
                  id="ciudad"
                  v-model="evento.ciudad"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
                <small
                  v-for="error in errores.ciudad"
                  :key="error"
                  class="text-danger"
                >{{ error }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group
              label="Telefono"
              label-for="telefono"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Telefono"
                rules=""
              >
                <b-form-input
                  id="telefono"
                  v-model="evento.telefono"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
                <small
                  v-for="error in errores.telefono"
                  :key="error"
                  class="text-danger"
                >{{ error }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group
              label="Latitud"
              label-for="latitud"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Latitud"
                rules="required"
              >
                <b-form-input
                  id="latitud"
                  v-model="ubicacion.position.lat"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
                <small
                  v-for="error in errores.latitud"
                  :key="error"
                  class="text-danger"
                >{{ error }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Longitud"
              label-for="longitud"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Longitud"
                rules="required"
              >
                <b-form-input
                  id="longitud"
                  v-model="ubicacion.position.lng"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
                <small
                  v-for="error in errores.longitud"
                  :key="error"
                  class="text-danger"
                >{{ error }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <div>
              <l-map
                :zoom.sync="zoom"
                :options="mapOptions"
                :center="center"
                style="height: 500px; width: 100%"
              >
                <l-tile-layer
                  :url="url"
                  :attribution="attribution"
                />
                <l-marker
                  :visible="ubicacion.visible"
                  :draggable="ubicacion.draggable"
                  :lat-lng.sync="ubicacion.position"
                  @click="alert(ubicacion)"
                >
                  <l-popup content="hola" />
                </l-marker>
              </l-map>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="12">
            <h3 class="pt-2">Detalles del Organizador</h3>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <b-form-group
              label="Nombre del Organizador"
              label-for="nombre_organizador"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Fax"
                rules=""
              >
                <b-form-input
                  id="nombre_organizador"
                  v-model="evento.nombre_organizador"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
                <small
                  v-for="error in errores.nombre_organizador"
                  :key="error"
                  class="text-danger"
                >{{ error }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Telefono"
              label-for="telefono_organizador"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Telefono Organizador"
                rules=""
              >
                <b-form-input
                  id="telefono_organizador"
                  v-model="evento.telefono_organizador"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
                <small
                  v-for="error in errores.telefono_organizador"
                  :key="error"
                  class="text-danger"
                >{{ error }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

        </b-row>
        <b-row>
          <b-col md="12">
            <h3>Redes Sociales</h3>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <b-form-group
              label="URL Facebook"
              label-for="url_facebook"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Fax"
                rules=""
              >
                <b-form-input
                  id="url_facebook"
                  v-model="evento.url_facebook"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
                <small
                  v-for="error in errores.url_facebook"
                  :key="error"
                  class="text-danger"
                >{{ error }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="URL Instagram"
              label-for="url_instagram"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="URL Instagram"
                rules=""
              >
                <b-form-input
                  id="url_instagram"
                  v-model="evento.url_instagram"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
                <small
                  v-for="error in errores.url_instagram"
                  :key="error"
                  class="text-danger"
                >{{ error }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="URL Youtube"
              label-for="url_youtube"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="URL Youtube"
                rules=""
              >
                <b-form-input
                  id="url_youtube"
                  v-model="evento.url_youtube"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
                <small
                  v-for="error in errores.url_youtube"
                  :key="error"
                  class="text-danger"
                >{{ error }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            class="mt-50"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-1"
              @click="guardar"
            >
              Guardar
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              :to="{ name: 'eventos' }"
            >
              Cancelar
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <b-row />

    <!--/ form -->
  </b-card>
</template>

<script>
import axios from '@axios'
import {
  required,
  email,
  confirmed,
  url,
  between,
  alpha,
  integer,
  password,
  min,
  digits,
  alphaDash,
  length,
} from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BFormCheckbox,
  BCard,
  BMedia,
  BAvatar,
  BCardText,
  BMediaAside,
  BMediaBody,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BImg,
  BFormFile,
  BLink,
  BButton,
  BFormTimepicker,
  BFormDatepicker,
} from 'bootstrap-vue'

import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'
import {
  LMap,
  LTileLayer,
  LMarker,
  LPolyline,
  LLayerGroup,
  LTooltip,
  LPopup,
  LControlZoom,
  LControlAttribution,
  LControlScale,
  LControlLayers,
} from 'vue2-leaflet'
import { latLngBounds, Icon } from 'leaflet'
import CkEditorPersonalizado from '@/componentes/CkEditorPersonalizado'
import router from '@/router'
import 'leaflet/dist/leaflet.css'

delete Icon.Default.prototype._getIconUrl
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
})

export default {
  components: {
    BFormCheckbox,
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BMediaAside,
    BMediaBody,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect,
    LMap,
    LTileLayer,
    LMarker,
    LPolyline,
    LLayerGroup,
    LTooltip,
    LPopup,
    LControlZoom,
    LControlAttribution,
    LControlScale,
    LControlLayers,
    ValidationProvider,
    ValidationObserver,
    CkEditorPersonalizado,
    BFormTimepicker,
    BFormDatepicker,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,

      min,
      errores: [],

      evento: {
        nombre_fiscal: '',
        nombre: '',
        direccion: '',
        telefono: '',
        fax: '',
        ubicacion: '',
      },
      eventoFile: null,
      imagenMiniatura: '',
      eventoOption: [],

      ubicacion: {
        position: { lat: -19.062117883514652, lng: -65.25878906250001 },
        visible: true,
        draggable: true,
      },
      selected: '0',
      zoom: 13,
      center: [-19.062117883514652, -65.25878906250001],
      mapOptions: {
        zoomControl: true,
        attributionControl: true,
        zoomSnap: true,
      },
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      attribution:
        '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
    }
  },
  computed: {
    formTitle() {
      return this.evento.id ? 'Editar Evento' : 'Nueva Evento'
    },
  },
  watch: {
    'evento.nombre': function () {
      this.errores.nombre = []
    },
    'evento.nombre_fiscal': function () {
      this.errores.nombre_fiscal = []
    },
    'evento.direccion': function () {
      this.errores.direccion = []
    },
    'evento.telefono': function () {
      this.errores.telefono = []
    },
    'evento.fax': function () {
      this.errores.fax = []
    },
    'evento.ubicacion': function () {
      this.errores.ubicacion = []
    },
    'ubicacion.position.lat': function () {
      this.errores.latitud = []
    },
    'ubicacion.position.lng': function () {
      this.errores.longitud = []
    },
  },
  created() {
    if (router.currentRoute.params.id) {
      axios
        .get(`/eventos/${router.currentRoute.params.id}`)
        .then(response => {
          this.evento = response.data.data
          this.ubicacion.position.lat = this.evento.latitud
          this.ubicacion.position.lng = this.evento.longitud
          this.center = [this.evento.latitud, this.evento.longitud]
          if (this.evento.evento_padre) {
            this.evento.eventoDependiente = this.evento.evento_padre
            this.selected = '1'
            this.getAlleventos()
          }
        })
        .catch(error => console.error(error))
    }
  },
  setup() {
    const refInputEl = ref(null)
    const refPreviewEl = ref(null)
    const imagen = ref('')

    const { inputImageRenderer } = useInputImageRenderer(
      refInputEl,
      base64 => {
        refPreviewEl.value.src = base64
        imagen.value = base64
      },
    )

    return {
      refInputEl,
      refPreviewEl,
      inputImageRenderer,
      imagen,
    }
  },

  methods: {
    async success(Mensaje) {
      await this.$swal({
        title: Mensaje,

        icon: 'success',
        timer: 2000,
        confirmButtonText: 'Aceptar',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
      router.push({ name: 'eventos' })
    },
    async getAllEventos() {
      await axios
        .get('/getAlleventosSelect')
        .then(response => {
          this.eventoOption = response.data.data
        })
        .catch(error => reject(error))
    },

    traereventos() {
      if (this.selected == '1') this.getAlleventos()
    },

    alert(item) {
      // eslint-disable-next-line no-alert
      alert(`this is ${JSON.stringify(item)}`)
    },
    validar() {
      this.$refs.eventoRules.validate().then(success => {
        if (success) {
          this.guardar()
        }
      })
    },
    async guardar() {
      const formData = new FormData()
      formData.append('nombre', this.evento.nombre)
      formData.append('nombre_fiscal', this.evento.nombre_fiscal)
      formData.append('direccion', this.evento.direccion)
      formData.append('telefono', this.evento.telefono)
      formData.append('fax', this.evento.fax)
      formData.append('ubicacion', this.evento.ubicacion)
      formData.append('latitud', this.ubicacion.position.lat)
      formData.append('longitud', this.ubicacion.position.lng)
      formData.append('imagen', this.eventoFile)

      if (this.selected == '1') {
        formData.append(
          'evento_padre_id',
          this.evento.eventoDependiente.id,
        )
      }
      if (this.evento.id) {
        formData.append('_method', 'PUT')
        axios
          .post(`/eventos/${this.evento.id}`, formData)
          .then(response => {
            this.success('Actualizado con Exito')
          })
          .catch(error => {
            if (error.response.status === 422) {
              this.errores = error.response.data.errors
            } else {
              console.error(error)
            }
          })
      } else {
        axios
          .post('/eventos', formData)
          .then(response => {
            this.success('Insertado con Exito')
            // router.push({ name: 'eventos' });
          })
          .catch(error => {
            if (error.response.status === 422) {
              this.errores = error.response.data.errors
            } else {
              console.error(error)
            }
          })
      }
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/quill.scss";
@import "@core/scss/vue/pages/page-blog.scss";
@import "@core/scss/vue/libs/map-leaflet.scss";
.custom-file-input:lang(es) ~ .custom-file-label::after {
  content: "Buscar";
}

.vue2leaflet-map {
  &.leaflet-container {
    height: 350px;
  }
}
</style>
